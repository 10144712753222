import * as React from "react";

import Seo from "../components/Seo/Seo";
import Layout from "../Layouts/Layout/Layout";

/**
 * 404 page not created yet, but will trigger on a 404. Development mode will just give a list of all active pages.
 */

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
